<template>
  <v-container>
    <v-card tile>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        item-key="id"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.displayname`]="{ item }">
          {{ item.displayname }}
        </template>
        <template v-slot:[`item.Address1`]="{ item }">
          {{ item.Address1 }} {{ item.Address2 }}, {{ item.City }},
          {{ item.State }} {{ item.Zip }}
        </template>

        <template v-slot:[`item.Direction`]="{ item }">
          <span v-bind="attrs" v-on="on">
            <v-img
              src="../assets/location.png"
              height="40px"
              max-heigh="40px"
              max-width="40px"
              style="cursor: pointer"
              @click="getDirections(item.mapslink)"
            ></v-img>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { auth, messages } from '../sharedPlugin';
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: 'Name', value: 'displayname' },
        { text: 'Street', value: 'Address1' },
        { text: '', value: 'Direction', sortable: false, width: '40' }
      ],

      items: []
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },

  created() {
    this.load();
  },

  methods: {
    ...messages.mapMethods(['newPresetMessage']),
    getDirections(directions) {
      window.open(directions, '_blank');
    },
    load() {
      this.loading = true;
      // api.Labs.getAll()
      //   .then((result) => {
      //     this.items = result;
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });

      axios
        .post(
          process.env.VUE_APP_API_URL + '/api/v1/connect/locations/getAll',
          {
            id: this.user.clinicId
          }
        )
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
};
</script>
